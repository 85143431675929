<template>
    <BT-Blade-Item
        :bladesData="bladesData"
        bladeName="zone"
        navigation="zones"
        title="Zone"
        defaultFrameWidth="700"
        @fetched="zoneFetched"
        :onNew="x => { return createZone(x) }">
        <template slot-scope="{ item, data }">
            <BT-Field-String
                v-model="item.zoneName"
                label="Zone Name"
                :isEditing="data.isEditing || data.isNew" />

            <GmapMap
                :center="center"
                :zoom="7"
                style="width: 100%; min-height: 300px; height: 430px;"
                :options="{
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: true,
                    disableDefaultUi: false }">

                    <gmap-polygon 
                        :paths="item.boundary"
                        :draggable="data.isEditing || data.isNew"
                        @paths_changed="($event) => { updatePointerPaths($event, item) }"
                        :editable="data.isEditing || data.isNew"
                        :fillOpacity="0.5"
                        class="polygon" />
                </GmapMap>
        </template>
    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Zone-Blade',
    data: function() {
        return {
            center: { lat: -38, lng: 144 },
            zone: null
        }
    },
    props: {
        bladesData: null
    },
    methods: {
        createZone() {
            return {
                id: null,
                boundary: this.getDefaultArea(),
                zoneName: null
            };
        },
        getDefaultArea() {
            return [ 
                { lat: this.center.lat - 1, lng: this.center.lng + 1 },
                { lat: this.center.lat - 1, lng: this.center.lng - 1 },
                { lat: this.center.lat + 1, lng: this.center.lng - 1 },
                { lat: this.center.lat + 1, lng: this.center.lng + 1 }
            ];
        },
        zoneFetched(zone) {
            if (zone != null) {
                this.zone = zone;
            }
        },
        updatePointerPaths(mvcArray, zone) {
            let paths = [];
            for (let i=0; i<mvcArray.getLength(); i++) {
              let path = [];
              for (let j=0; j<mvcArray.getAt(i).getLength(); j++) {
                let point = mvcArray.getAt(i).getAt(j);
                path.push({lat: point.lat(), lng: point.lng()});
              }
              paths.push(path);
            }
            zone.boundary = paths[0];
        },
    }
}
</script>