var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Item',{attrs:{"bladesData":_vm.bladesData,"bladeName":"zone","navigation":"zones","title":"Zone","defaultFrameWidth":"700","onNew":function (x) { return _vm.createZone(x) }},on:{"fetched":_vm.zoneFetched},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var data = ref.data;
return [_c('BT-Field-String',{attrs:{"label":"Zone Name","isEditing":data.isEditing || data.isNew},model:{value:(item.zoneName),callback:function ($$v) {_vm.$set(item, "zoneName", $$v)},expression:"item.zoneName"}}),_c('GmapMap',{staticStyle:{"width":"100%","min-height":"300px","height":"430px"},attrs:{"center":_vm.center,"zoom":7,"options":{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true,
                disableDefaultUi: false }}},[_c('gmap-polygon',{staticClass:"polygon",attrs:{"paths":item.boundary,"draggable":data.isEditing || data.isNew,"editable":data.isEditing || data.isNew,"fillOpacity":0.5},on:{"paths_changed":function ($event) { _vm.updatePointerPaths($event, item) }}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }